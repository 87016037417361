import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

// import styles from './article-preview.module.css'

const ArticleItem = styled.div`
  display: grid;
  grid-template-columns: 30vmin 1fr;
  grid-gap: 3vmin;
  img {
    object-fit: cover;
    width: 100%;
    height: 22.5vmin !important;
  }
  height: 22.5vmin;
  overflow: hidden;
`
const ArticleThumb = styled.div`
  width: 30vmin;
  height: 22.5vmin;
  border-radius: 1vmin;
  overflow:hidden
`

const ArticleDesc = styled.div`
  h1, h2, h3, h4, h5 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`

export default ({ article }) => {
  return (
  <ArticleItem>
    <ArticleThumb>
      <Img style={{height: 'auto'}} alt="" fluid={article.heroImage.fluid} />
    </ArticleThumb>

    <ArticleDesc>
      <h3>
        {article.title}
      </h3>
      <small>{article.publishDate}</small>
      <div
        dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }}
      />
    </ArticleDesc>
  </ArticleItem>
)}
