import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/ArticlePreview'
import Wrapper from '../components/Wrapper'
import styled from 'styled-components'

const ArticleList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ArticleItem = styled.li`
  padding: 2vmin 0;
  border-radius: 1vmin;
  transition: all .25s;
  margin: 0;
  :hover{
    background: #f1f1f4;
    padding-left: 2vmin;
    padding-right: 2vmin;
    margin: 0 -2vmin;
  }
  > a {
    display:block;
    width: 100%;
    height: 100%;
  }

`

export default function RootIndex ({location}) {
  const {
    site:{siteMetadata:{title:siteTitle}},
    allContentfulBlogPost:{edges:posts}} = useStaticQuery(
        graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
          allContentfulBlogPost(
            sort: { fields: [publishDate], order: DESC }
          ) {
            edges {
              node {
                title
                slug
                publishDate(formatString: "MMMM Do, YYYY")
                tags
                heroImage {
                  fluid( maxWidth: 350, maxHeight: 350, resizingBehavior: SCALE) {
                    ...GatsbyContentfulFluid_tracedSVG
                  }
                }
                description {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      `)

  return (
    <Layout location={location}>
      <div style={{ background: '#fff' }}>
        <Helmet title={siteTitle} />
        {/* <Hero data={author.node} /> */}
        <Wrapper>
          {/* <h2 className="section-headline">Recent articlessdf</h2> */}
          <ArticleList>
            {posts.map(({ node }) => {
              return (
                <ArticleItem key={node.slug}>
                  <Link to={`/blog/${node.slug}`}>
                    <ArticlePreview article={node} />
                  </Link>
                </ArticleItem>
              )
            })}
          </ArticleList>
        </Wrapper>
      </div>
    </Layout>
  )
}
